// TODO: A way to add Icons that don't already exist?
// - Register them and add
// - Include SVG Source/Inline?
// - By URL, or own component?

// What KINDS of toolbar buttons do we have...
// - One's that dispatch commands
// - One's that set tool's active
// - More custom, like CINE
//    - Built in for one's like this, or custom components?

// Visible?
// Disabled?
// Based on contexts or misc. criteria?
//  -- ACTIVE_ROUTE::VIEWER
//  -- ACTIVE_VIEWPORT::CORNERSTONE
// setToolActive commands should receive the button event that triggered
// so we can do the "bind to this button" magic

const TOOLBAR_BUTTON_TYPES = {
  COMMAND: 'command',
  SET_TOOL_ACTIVE: 'setToolActive',
  BUILT_IN: 'builtIn',
};

const TOOLBAR_BUTTON_BEHAVIORS = {
  CINE: 'CINE',
  DOWNLOAD_SCREEN_SHOT: 'DOWNLOAD_SCREEN_SHOT',
};

/* TODO: Export enums through a extension manager. */
const enums = {
  TOOLBAR_BUTTON_TYPES,
  TOOLBAR_BUTTON_BEHAVIORS,
};

const definitions = [

  // {
  //   id: 'Brush',
  //   label: 'Brush',
  //   icon: 'dental-probe',
  //   //
  //   type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
  //   commandName: 'setToolActive',
  //   commandOptions: { toolName: 'Brush' },
  // },

  //The image corresponding to the scroll position is displayed when the stack scroll is clicked.
  {
    id: 'StackScroll',
    label:
      'StackScroll tool button is used to display the image corresponding to the scrolls position.',
    icon: 'bars',
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'StackScroll' },
  },
  {
    id: 'Zoom',
    label: 'Zoom button lets, you change the size of the image.',
    icon: 'search-plus',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'Zoom' },
  },
  {
    id: 'Wwwc',
    label: 'Level tool button is used to level the brightness of an image.',
    icon: 'level',

    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'Wwwc' },
  },
  {
    id: 'Hand',
    label: 'Hand tool, is used to change the image position through the mouse.',
    icon: 'Hand',

    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'Pan' },
  },
  {
    id: 'Length',
    label: 'Length tool is used to measure the length between two endpoints.',
    icon: 'scale',

    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    context: 'ACTIVE_VIEWPORT::CORNERSTONE',
    commandOptions: { toolName: 'Length' },
  },
  {
    id: 'More',
    label: 'More',
    icon: 'ellipse-circle',
    buttons: [
      {
        id: 'Magnify',
        label:
          'Magnifier tool, is used to magnify (enlarge) a certain area of the image.',
        icon: 'magnify',

        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'Magnify' },
      },
      {
        id: 'WwwcRegion',
        label:
          'WindowROI tool, measures the measurement of images of the size and shape of a particular object.',
        icon: 'window',

        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'WwwcRegion' },
      },
      {
        id: 'DragProbe',
        label: 'Probe tool is used to see pixel values.',
        icon: 'dental-probe',

        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'DragProbe' },
      },
      {
        id: 'EllipticalRoi',
        label: 'Ellipse is used to measure Area, Mean, and Standard deviation.',
        icon: 'ellipse',

        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'EllipticalRoi' },
      },
      {
        id: 'RectangleRoi',
        label:
          'Rectangle is used to measure Area, Mean, and Standard deviation.',
        icon: 'rectangular',

        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'RectangleRoi' },
      },
      {
        id: 'Invert',
        label: 'Invert tool, inverts the color of the image.',
        icon: 'invert',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'invertViewport',
        commandOptions: { toolName: 'invertViewport' },
      },
      {
        id: 'RotateRight',
        label: 'RotateRight tool can be used to rotate the dicom image.',
        icon: 'rotate-r',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'rotateViewportCW',
        commandOptions: { toolName: 'rotateViewportCW' },
      },
      {
        id: 'FlipH',
        label: 'FlipH tool, flips the image in horizontal direction.',
        icon: 'flip-h',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'flipViewportHorizontal',
        commandOptions: { toolName: 'flipViewportHorizontal' },
      },
      {
        id: 'FlipV',
        label: 'FlipV tool, flips the image in vertical direction.',
        icon: 'flip-v',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'flipViewportVertical',
        commandOptions: { toolName: 'flipViewportVertical' },

      },
      /* {
        id: 'Undo',
        label: 'Undo',
        icon: 'undo',
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'undoAnnotation',
      },
      {
        id: 'Redo',
        label: 'Redo',
        icon: 'redo',
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'redoAnnotation',
      }, */

      {
        id: 'FreehandRoi',
        label: 'FreehandRoi',
        icon: 'freehand',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        context: 'ACTIVE_VIEWPORT::CORNERSTONE',
        commandOptions: { toolName: 'FreehandRoi' },
      },
      {
        id: 'Bidirectional',
        label:
          'Bidirectional tool takes four points and calculates length and width.',
        icon: 'transfer',

        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        context: 'ACTIVE_VIEWPORT::CORNERSTONE',
        commandOptions: { toolName: 'Bidirectional' },
      },

      {
        id: 'Download',
        label:
          'Download is used to save and download images when clicking on the save button the image will be saved on the bucket and marked on the key image folder.',
        icon: 'image',

        type: TOOLBAR_BUTTON_TYPES.BUILT_IN,
        options: {
          behavior: TOOLBAR_BUTTON_BEHAVIORS.DOWNLOAD_SCREEN_SHOT,
          togglable: true,
        },
      },
    ],
  },
  {
    id: 'ArrowAnnotate',
    label: 'Annotation tool is used to highlight specific points.',
    icon: 'measure-non-target',
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'ArrowAnnotate' },
    context: 'ACTIVE_VIEWPORT::CORNERSTONE',
  },
  {
    id: 'Angle',
    label: 'Angle tool, measures the angle between three points.',
    icon: 'measure-temp',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'Angle' },
    context: 'ACTIVE_VIEWPORT::CORNERSTONE',
  },
  // {
  //   id: 'CopyLink',
  //   label: 'Link',
  //   icon: 'link',
  //   type: TOOLBAR_BUTTON_TYPES.BUILT_IN,
  //   options: {
  //     behavior: TOOLBAR_BUTTON_BEHAVIORS.SET_TOOL_Copy_Link,
  //     togglable: true,
  //   },
  // },
  {
    id: 'Clear',
    // className: 'Clear',
    label: 'ClearDicom tool can be used to clear the dicom image.',
    icon: 'clear',
    //
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'clearAnnotations',
    commandOptions: { toolName: 'clearAnnotations' },
  },

  // {
  //   id: 'react-screen-cap',
  //   label:
  //     'react-screen-cap tool is used for screen capture of the dicom screen',
  //   icon: 'react-screen-cap',

  //   type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
  //   commandName: 'resetViewport',
  //   //commandOptions: { toolName: 'Reset' },
  // },
  {
    id: 'Cine',
    label: 'CINE',
    icon: 'youtube',

    type: TOOLBAR_BUTTON_TYPES.BUILT_IN,
    options: {
      behavior: TOOLBAR_BUTTON_BEHAVIORS.CINE,
    },
  },

  // {
  //   id: 'Exit2DMPR',
  //   label: 'Exit 2D MPR',
  //   icon: 'times',
  //   //
  //   type: TOOLBAR_BUTTON_TYPES.COMMAND,
  //   commandName: 'setCornerstoneLayout',
  //   context: 'ACTIVE_VIEWPORT::VTK',
  // },
];

export default {
  definitions,
  defaultContext: 'ACTIVE_VIEWPORT::CORNERSTONE',
};
