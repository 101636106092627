import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import DCMCloud from '@dcmcloud/core';

import setCornerstoneLayout from './utils/setCornerstoneLayout.js';
import { getEnabledElement } from './state';
import CornerstoneViewportDownloadForm from './CornerstoneViewportDownloadForm';
const scroll = cornerstoneTools.import('util/scroll');
import MeasurementHistory from './measurementHistory.js';

const { studyMetadataManager } = DCMCloud.utils;
const { setViewportSpecificData } = DCMCloud.redux.actions;
let redoSteps = [];

const refreshCornerstoneViewports = () => {
  cornerstone.getEnabledElements().forEach(enabledElement => {
    if (enabledElement.image) {
      cornerstone.updateImage(enabledElement.element);
    }
  });
};

const commandsModule = ({ servicesManager }) => {
  const actions = {
    rotateViewport: ({ viewports, rotation }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);

      if (enabledElement) {
        let viewport = cornerstone.getViewport(enabledElement);
        viewport.rotation += rotation;
        cornerstone.setViewport(enabledElement, viewport);
      }
    },
    flipViewportHorizontal: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      if (enabledElement) {
        let viewport = cornerstone.getViewport(enabledElement);
        viewport.hflip = !viewport.hflip;
        cornerstone.setViewport(enabledElement, viewport);
      }
    },

    flipViewportVertical: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);

      console.log('fliped viewport:', cornerstone.getViewport(enabledElement));
      if (enabledElement) {
        let viewport = cornerstone.getViewport(enabledElement);
        viewport.vflip = !viewport.vflip;
        cornerstone.setViewport(enabledElement, viewport);
        console.log(
          'fliped viewport:',
          cornerstone.getViewport(enabledElement)
        );
      }
    },
    scaleViewport: ({ direction, viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      const step = direction * 0.15;

      if (enabledElement) {
        if (step) {
          let viewport = cornerstone.getViewport(enabledElement);
          viewport.scale += step;
          cornerstone.setViewport(enabledElement, viewport);
        } else {
          cornerstone.fitToWindow(enabledElement);
        }
      }
    },
    resetViewport: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);

      if (enabledElement) {
        cornerstone.reset(enabledElement);
      }
    },
    invertViewport: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);

      if (enabledElement) {
        let viewport = cornerstone.getViewport(enabledElement);
        viewport.invert = !viewport.invert;
        cornerstone.setViewport(enabledElement, viewport);
      }
      // } catch (error) {
      //   console.error('Error in invertViewport:', error);
      // }
    },
    // TODO: this is receiving `evt` from `ToolbarRow`. We could use it to have
    //       better mouseButtonMask sets.
    setToolActive: ({ toolName }) => {
      if (!toolName) {
        console.warn('No toolname provided to setToolActive command');
      }
      cornerstoneTools.setToolActive(toolName, { mouseButtonMask: 1 });
    },
    // setRedo: ({ toolName, viewports }) => {
    //   const element = getEnabledElement(viewports.activeViewportIndex);

    //   if (!element) {
    //     return;
    //   }

    //   if (!toolName) {
    //     console.warn('No toolname provided to setToolActive command');
    //     return;
    //   }

    //   console.log(toolName, element, 'redoModuleeee');

    //   const stateDataa = cornerstoneTools.getElementToolStateManager(element);
    //   const stackData = stateDataa.toolState.stack.data;
    //   const specficToolstate = cornerstoneTools.getToolState(element, toolName);
    //   console.log(specficToolstate, 'stateData3333');
    //   // Capture redo steps (imageIds) and associated tool states
    //   const redoSteps = stackData.map(stack => ({
    //     imageId: stack.imageIds[stack.currentImageIdIndex],
    //     toolState: stack.currentTool,
    //   }));

    //   // Log captured imageIds for debugging
    //   redoSteps.forEach(step => {
    //     console.log(step.imageId, 'Redo Step Image ID');
    //     console.log(step.toolState, 'Redo Step Tool State');
    //   });

    //   // Apply redo logic here (example: load and display the last imageId)
    //   if (redoSteps.length > 0) {
    //     const nextStep = redoSteps.pop();
    //     const nextImageId = nextStep.imageId;
    //     const nextToolState = nextStep.toolState;

    //     console.log(nextToolState, 'nextimageeeee'); // Get the next imageId from redo steps

    //     cornerstone.loadImage(nextImageId).then(image => {
    //       // Display the image
    //       cornerstone.displayImage(element, image);

    //       // Apply the tool state (example: setting angle tool)
    //       if (nextToolState) {
    //         // Example assuming 'angle' tool and setting active
    //         cornerstoneTools.setToolActive('Angle', { mouseButtonMask: 1 });
    //         cornerstoneTools.addToolState(element, 'Angle', nextToolState);
    //       }
    //     });
    //   } else {
    //     console.warn('No redo steps available');
    //   }
    // },

    setRedo: ({ toolName, viewports }) => {
      const element = getEnabledElement(viewports.activeViewportIndex);
      //const element = getEnabledElement();
      const toolState = cornerstoneTools.getToolState(element, toolName);

      if (!element) {
        return;
      }

      if (!toolName) {
        console.warn('No toolname provided to setToolActive command');
        return;
      }

      const stateDataa = cornerstoneTools.getElementToolStateManager(element);
      const stackData = stateDataa.toolState.stack.data;
      const getstates = stateDataa.saveToolState();

      // Capture redo steps (imageIds) directly within the setRedo function
      const redoSteps = stackData[0].imageIds.slice(); // Copy the array of imageIds

      // Log captured imageIds for debugging
      redoSteps.forEach(imageId => {
        console.log(imageId, 'Redo Step Image ID');
      });

      // Apply redo logic here (example: load and display the last imageId)
      if (redoSteps.length > 0) {
        const nextImageId = redoSteps.pop();
        cornerstone.loadImage(nextImageId).then(image => {
          cornerstone.displayImage(element, image);
        });
      } else {
        console.warn('No redo steps available');
      }
    },

    // setRedo: ({ toolName, viewports }) => {
    //   const element = getEnabledElement(viewports.activeViewportIndex);

    //   //const toolState = cornerstoneTools.getToolState(element, 'Angle');
    //   if (!element) {
    //     return;
    //   }
    //   if (!toolName) {
    //     console.warn('No toolname provided to setToolActive command');
    //   }
    //   if (toolName) {
    //     console.log(toolName, element, 'redoModuleeee');
    //     const stateData = cornerstoneTools.getElementToolStateManager(element);
    //     const stackData = stateData.toolState.stack.data;

    //     stackData[0].imageIds.forEach(imageId => {
    //       console.log(imageId, 'ImageIDsssss');
    //     });

    //     const toolState = cornerstoneTools.getToolState(element, toolName);
    //     if (!toolState || !toolState.data || toolState.data.length === 0) {
    //       console.warn(`No tool state found for ${toolName} on element`);
    //       return;
    //     }
    //   }
    // },
    setUndo: ({ toolName, viewports }) => {
      const element = getEnabledElement(viewports.activeViewportIndex);

      const toolNames = [
        'ArrowAnnotate',
        'Length',
        'EllipticalRoi',
        'RectangleRoi',
        'Bidirectional',
        'Angle',
        'FreehandRoi',
      ];

      if (!element) {
        return;
      }
      if (!toolName) {
        console.warn('No toolname provided to setToolActive command');
      }

      if (toolNames.includes(toolName)) {
        cornerstoneTools.clearToolState(element, toolName);
      } else {
        cornerstone.reset(element);
      }
      cornerstone.updateImage(element);
    },

    undoAnnotation: ({ viewports }) => {
      console.log('viewports7890', viewports);
      let measurementRedoHistory = MeasurementHistory.measurementRedoHistory;
      const element = getEnabledElement(viewports.activeViewportIndex);
      if (!element) {
        return;
      }

      const enabledElement = cornerstone.getEnabledElement(element);
      // const enabledElement = cornerstone.getEnabledElements();

      console.log('enabledElement', enabledElement);
      if (!enabledElement || !enabledElement.image) {
        return;
      }

      const {
        toolState,
      } = cornerstoneTools.globalImageIdSpecificToolStateManager;

      console.log(toolState, 'toolState');
      if (
        !toolState ||
        toolState.hasOwnProperty(enabledElement.image.imageId) === false
      ) {
        return;
      }

      console.log(toolState, 'toolState');

      const imageIdToolState = toolState[enabledElement.image.imageId];

      let measurementToRemove;

      let measurementDataHistory = MeasurementHistory.measurementHistory;

      console.log(measurementDataHistory);

      //New Functionality start
      if (measurementDataHistory) {
        measurementDataHistory.forEach(history => {
          if (
            viewports.viewportSpecificData[viewports.activeViewportIndex]
              .SeriesInstanceUID === history.SeriesInstanceUID
          ) {
            measurementToRemove = {
              toolType:
                history.measurementHistory[
                  history.measurementHistory.length - 1
                ].toolType,
              measurementData:
                history.measurementHistory[
                  history.measurementHistory.length - 1
                ].measurementData,
            };

            if (measurementRedoHistory.length === 0) {
              measurementRedoHistory.unshift({
                SeriesInstanceUID:
                  viewports.viewportSpecificData[viewports.activeViewportIndex]
                    .SeriesInstanceUID,
                measurementHistory: [
                  history.measurementHistory[
                    history.measurementHistory.length - 1
                  ],
                ],
              });
            } else {
              let updateHistory = false;
              measurementRedoHistory.forEach((histroy, index) => {
                if (
                  viewports.viewportSpecificData[viewports.activeViewportIndex]
                    .SeriesInstanceUID === histroy.SeriesInstanceUID
                ) {
                  updateHistory = true;

                  measurementRedoHistory[index] = {
                    SeriesInstanceUID:
                      viewports.viewportSpecificData[
                        viewports.activeViewportIndex
                      ].SeriesInstanceUID,
                    measurementHistory: [
                      ...measurementRedoHistory[index].measurementHistory,
                      measurementToRemove,
                    ],
                  };
                }
              });

              if (!updateHistory) {
                measurementRedoHistory.push({
                  SeriesInstanceUID:
                    viewports.viewportSpecificData[
                      viewports.activeViewportIndex
                    ].SeriesInstanceUID,
                  measurementHistory: [measurementToRemove],
                });
              }
            }

            history.measurementHistory.splice(
              history.measurementHistory.length - 1,
              1
            );
          }
        });
      }

      console.log(measurementToRemove, 'measurementToRemove');

      DCMCloud.measurements.MeasurementHandlers.onSingleMeasurementUndo({
        detail: {
          toolType: measurementToRemove.toolType,
          measurementData: measurementToRemove.measurementData,
        },
      });

      console.log('measurementDataHistory', measurementDataHistory);
      console.log('measurementUndoHistory', measurementRedoHistory);
    },

    redoAnnotation: ({ viewports }) => {
      let measurementRedoHistory = MeasurementHistory.measurementRedoHistory;
      const element = getEnabledElement(viewports.activeViewportIndex);
      if (!element) {
        return;
      }

      const enabledElement = cornerstone.getEnabledElement(element);
      if (!enabledElement || !enabledElement.image) {
        return;
      }

      const {
        toolState,
      } = cornerstoneTools.globalImageIdSpecificToolStateManager;
      if (
        !toolState ||
        toolState.hasOwnProperty(enabledElement.image.imageId) === false
      ) {
        return;
      }

      const imageIdToolState = toolState[enabledElement.image.imageId];

      let measurementsToAdd;

      let measurementDataHistory = MeasurementHistory.measurementHistory;

      //NEw Functionality start
      if (measurementRedoHistory.length > 0) {
        measurementRedoHistory.forEach((history, index) => {
          if (
            viewports.viewportSpecificData[viewports.activeViewportIndex]
              .SeriesInstanceUID === history.SeriesInstanceUID
          ) {
            measurementsToAdd = {
              toolType:
                history.measurementHistory[
                  history.measurementHistory.length - 1
                ].toolType,
              measurementData:
                history.measurementHistory[
                  history.measurementHistory.length - 1
                ].measurementData,
            };

            if (measurementDataHistory.length === 0) {
              measurementDataHistory[index].push({
                SeriesInstanceUID:
                  viewports.viewportSpecificData[viewports.activeViewportIndex]
                    .SeriesInstanceUID,
                measurementHistory: [measurementsToAdd],
              });
            } else {
              let updateHistory = false;
              measurementDataHistory.forEach((histroy, index) => {
                if (
                  viewports.viewportSpecificData[viewports.activeViewportIndex]
                    .SeriesInstanceUID === histroy.SeriesInstanceUID
                ) {
                  updateHistory = true;

                  measurementDataHistory[index] = {
                    SeriesInstanceUID:
                      viewports.viewportSpecificData[
                        viewports.activeViewportIndex
                      ].SeriesInstanceUID,
                    measurementHistory: [
                      ...measurementDataHistory[index].measurementHistory,
                      measurementsToAdd,
                    ],
                  };
                }
              });

              if (!updateHistory) {
                measurementDataHistory.push({
                  SeriesInstanceUID:
                    viewports.viewportSpecificData[
                      viewports.activeViewportIndex
                    ].SeriesInstanceUID,
                  measurementHistory: [measurementsToAdd],
                });
              }
            }

            history.measurementHistory.splice(
              history.measurementHistory.length - 1,
              1
            );
          }
        });
      }

      console.log(measurementsToAdd, 'measurementsToAdd');

      DCMCloud.measurements.MeasurementHandlers.onSingleMeasurementRedo({
        detail: {
          toolType: measurementsToAdd.toolType,
          measurementData: measurementsToAdd.measurementData,
          element,
        },
      });
      //NEw Functionality end
      // refreshCornerstoneViewports();

      console.log('measurementDataHistory', measurementDataHistory);
      console.log('measurementRedoHistory', measurementRedoHistory);
    },
    clearAnnotations: ({ viewports }) => {
      const element = getEnabledElement(viewports.activeViewportIndex);
      if (!element) {
        return;
      }

      const enabledElement = cornerstone.getEnabledElement(element);
      if (!enabledElement || !enabledElement.image) {
        return;
      }

      const enabledElements = getEnabledElement(viewports.activeViewportIndex);

      if (enabledElement) {
        cornerstone.reset(enabledElements);
      }
      cornerstoneTools.clearToolState(element, 'ArrowAnnotate');
      cornerstoneTools.clearToolState(element, 'Length');
      cornerstoneTools.clearToolState(element, 'EllipticalRoi');
      cornerstoneTools.clearToolState(element, 'Bidirectional');
      cornerstoneTools.clearToolState(element, 'Angle');
      cornerstoneTools.clearToolState(element, 'FreehandRoi');

      cornerstone.updateImage(element);

      // cornerstoneTools.state.forEach(function (tool) {
      //   const toolState = cornerstoneTools.getToolState(element, tool.name)
      //   if (toolState) {
      //     // Modifying in a foreach? Probably not ideal

      //   }
      // })
      cornerstone.updateImage(element);

      const {
        toolState,
      } = cornerstoneTools.globalImageIdSpecificToolStateManager;
      if (
        !toolState ||
        toolState.hasOwnProperty(enabledElement.image.imageId) === false
      ) {
        return;
      }

      const imageIdToolState = toolState[enabledElement.image.imageId];

      const measurementsToRemove = [];

      Object.keys(imageIdToolState).forEach(toolType => {
        const { data } = imageIdToolState[toolType];

        data.forEach(measurementData => {
          const {
            _id,
            lesionNamingNumber,
            measurementNumber,
          } = measurementData;
          if (!_id) {
            return;
          }

          measurementsToRemove.push({
            toolType,
            _id,
            lesionNamingNumber,
            measurementNumber,
          });
        });
      });

      measurementsToRemove.forEach(measurementData => {
        DCMCloud.measurements.MeasurementHandlers.onRemoved({
          detail: {
            toolType: measurementData.toolType,
            measurementData,
          },
        });
      });
    },

    // clearAnnotations: ({ viewports }) => {
    //   const element = getEnabledElement(viewports.activeViewportIndex);
    //   if (!element) {
    //     return;
    //   }

    //   const enabledElement = cornerstone.getEnabledElement(element);
    //   if (!enabledElement || !enabledElement.image) {
    //     return;
    //   }

    //   const {
    //     toolState,
    //   } = cornerstoneTools.globalImageIdSpecificToolStateManager;
    //   if (
    //     !toolState ||
    //     toolState.hasOwnProperty(enabledElement.image.imageId) === false
    //   ) {
    //     return;
    //   }

    //   const imageIdToolState = toolState[enabledElement.image.imageId];

    //   const measurementsToRemove = [];

    //   let measurementRedoHistory = MeasurementHistory.measurementRedoHistory;
    //   let measurementDataHistory = MeasurementHistory.measurementHistory;

    //   if (measurementDataHistory) {
    //     measurementDataHistory.forEach((history, index) => {
    //       if (
    //         viewports.viewportSpecificData[viewports.activeViewportIndex]
    //           .SeriesInstanceUID === history.SeriesInstanceUID
    //       ) {
    //         measurementDataHistory.splice(index, 1);
    //       }
    //     });
    //   }
    //   if (measurementRedoHistory) {
    //     measurementRedoHistory.forEach((history, index) => {
    //       if (
    //         viewports.viewportSpecificData[viewports.activeViewportIndex]
    //           .SeriesInstanceUID === history.SeriesInstanceUID
    //       ) {
    //         measurementRedoHistory.splice(index, 1);
    //       }
    //     });
    //   }

    //   console.log('measurementDataHistory', measurementDataHistory);
    //   console.log('measurementRedoHistory', measurementRedoHistory);

    //   Object.keys(imageIdToolState).forEach(toolType => {
    //     const { data } = imageIdToolState[toolType];

    //     data.forEach(measurementData => {
    //       const {
    //         _id,
    //         lesionNamingNumber,
    //         measurementNumber,
    //       } = measurementData;
    //       if (!_id) {
    //         return;
    //       }

    //       measurementsToRemove.push({
    //         toolType,
    //         _id,
    //         lesionNamingNumber,
    //         measurementNumber,
    //       });
    //     });
    //   });

    //   measurementsToRemove.forEach(measurementData => {
    //     DCMCloud.measurements.MeasurementHandlers.onRemoved({
    //       detail: {
    //         toolType: measurementData.toolType,
    //         measurementData,
    //       },
    //     });
    //   });
    // },

    nextImage: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      scroll(enabledElement, 1);
    },
    previousImage: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      scroll(enabledElement, -1);
    },
    getActiveViewportEnabledElement: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      return enabledElement;
    },
    showDownloadViewportModal: ({ title, viewports }) => {
      const activeViewportIndex = viewports.activeViewportIndex;
      const { UIModalService } = servicesManager.services;
      console.log(servicesManager, 'title, viewports');
      if (UIModalService) {
        UIModalService.show({
          content: CornerstoneViewportDownloadForm,
          title,
          contentProps: {
            activeViewportIndex,
            onClose: UIModalService.hide,
          },
        });
      }
    },
    updateTableWithNewMeasurementData({
      toolType,
      measurementNumber,
      location,
      description,
    }) {
      // Update all measurements by measurement number
      const measurementApi = DCMCloud.measurements.MeasurementApi.Instance;
      const measurements = measurementApi.tools[toolType].filter(
        m => m.measurementNumber === measurementNumber
      );

      measurements.forEach(measurement => {
        measurement.location = location;
        measurement.description = description;

        measurementApi.updateMeasurement(measurement.toolType, measurement);
        console.log(measurement.toolType, 'measurementoolType');
      });

      measurementApi.syncMeasurementsAndToolData();

      refreshCornerstoneViewports();
    },
    getNearbyToolData({ element, canvasCoordinates, availableToolTypes }) {
      const nearbyTool = {};
      let pointNearTool = false;
      availableToolTypes.forEach(toolType => {
        const elementToolData = cornerstoneTools.getToolState(
          element,
          toolType
        );

        if (!elementToolData) {
          return;
        }

        elementToolData.data.forEach((toolData, index) => {
          let elementToolInstance = cornerstoneTools.getToolForElement(
            element,
            toolType
          );

          if (!elementToolInstance) {
            elementToolInstance = cornerstoneTools.getToolForElement(
              element,
              `${toolType}Tool`
            );
          }

          if (!elementToolInstance) {
            console.warn('Tool not found.');
            return undefined;
          }

          if (
            elementToolInstance.pointNearTool(
              element,
              toolData,
              canvasCoordinates
            )
          ) {
            pointNearTool = true;
            nearbyTool.tool = toolData;
            nearbyTool.index = index;
            nearbyTool.toolType = toolType;
          }
        });

        if (pointNearTool) {
          return false;
        }
      });

      return pointNearTool ? nearbyTool : undefined;
    },
    removeToolState: ({ element, toolType, tool }) => {
      cornerstoneTools.removeToolState(element, toolType, tool);
      cornerstone.updateImage(element);
    },
    setCornerstoneLayout: () => {
      setCornerstoneLayout();
    },
    setWindowLevel: ({ viewports, window, level }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);

      if (enabledElement) {
        let viewport = cornerstone.getViewport(enabledElement);

        viewport.voi = {
          windowWidth: Number(window),
          windowCenter: Number(level),
        };
        cornerstone.setViewport(enabledElement, viewport);
      }
    },
    jumpToImage: ({
      StudyInstanceUID,
      SOPInstanceUID,
      frameIndex,
      activeViewportIndex,
      refreshViewports = true,
    }) => {
      const study = studyMetadataManager.get(StudyInstanceUID);
      console.log('runs');

      const displaySet = study.findDisplaySet(ds => {
        return (
          ds.images &&
          ds.images.find(i => i.getSOPInstanceUID() === SOPInstanceUID)
        );
      });

      if (!displaySet) {
        return;
      }

      displaySet.SOPInstanceUID = SOPInstanceUID;
      displaySet.frameIndex = frameIndex;

      window.store.dispatch(
        setViewportSpecificData(activeViewportIndex, displaySet)
      );

      if (refreshViewports) {
        refreshCornerstoneViewports();
      }
    },
  };

  const definitions = {
    jumpToImage: {
      commandFn: actions.jumpToImage,
      storeContexts: [],
      options: {},
    },
    getNearbyToolData: {
      commandFn: actions.getNearbyToolData,
      storeContexts: [],
      options: {},
    },
    removeToolState: {
      commandFn: actions.removeToolState,
      storeContexts: [],
      options: {},
    },
    updateTableWithNewMeasurementData: {
      commandFn: actions.updateTableWithNewMeasurementData,
      storeContexts: [],
      options: {},
    },
    showDownloadViewportModal: {
      commandFn: actions.showDownloadViewportModal,
      storeContexts: ['viewports'],
      options: {},
    },
    setUndo: {
      commandFn: actions.setUndo,
      storeContexts: ['viewports'],
      options: {},
    },
    setRedo: {
      commandFn: actions.setRedo,
      storeContexts: ['viewports'],
      options: {},
    },
    getActiveViewportEnabledElement: {
      commandFn: actions.getActiveViewportEnabledElement,
      storeContexts: ['viewports'],
      options: {},
    },
    rotateViewportCW: {
      commandFn: actions.rotateViewport,
      storeContexts: ['viewports'],
      options: { rotation: 90 },
    },
    rotateViewportCCW: {
      commandFn: actions.rotateViewport,
      storeContexts: ['viewports'],
      options: { rotation: -90 },
    },
    invertViewport: {
      commandFn: actions.invertViewport,
      storeContexts: ['viewports'],
      options: {},
    },
    flipViewportVertical: {
      commandFn: actions.flipViewportVertical,
      storeContexts: ['viewports'],
      options: {},
    },
    flipViewportHorizontal: {
      commandFn: actions.flipViewportHorizontal,
      storeContexts: ['viewports'],
      options: {},
    },
    scaleUpViewport: {
      commandFn: actions.scaleViewport,
      storeContexts: ['viewports'],
      options: { direction: 1 },
    },
    scaleDownViewport: {
      commandFn: actions.scaleViewport,
      storeContexts: ['viewports'],
      options: { direction: -1 },
    },
    fitViewportToWindow: {
      commandFn: actions.scaleViewport,
      storeContexts: ['viewports'],
      options: { direction: 0 },
    },
    resetViewport: {
      commandFn: actions.resetViewport,
      storeContexts: ['viewports'],
      options: {},
    },
    clearAnnotations: {
      commandFn: actions.clearAnnotations,
      storeContexts: ['viewports'],
      options: {},
    },
    undoAnnotation: {
      commandFn: actions.undoAnnotation,
      storeContexts: ['viewports'],
      options: {},
    },
    redoAnnotation: {
      commandFn: actions.redoAnnotation,
      storeContexts: ['viewports'],
      options: {},
    },
    nextImage: {
      commandFn: actions.nextImage,
      storeContexts: ['viewports'],
      options: {},
    },
    previousImage: {
      commandFn: actions.previousImage,
      storeContexts: ['viewports'],
      options: {},
    },
    // TOOLS
    setToolActive: {
      commandFn: actions.setToolActive,
      storeContexts: [],
      options: {},
    },
    setZoomTool: {
      commandFn: actions.setToolActive,
      storeContexts: [],
      options: { toolName: 'Zoom' },
    },
    setCornerstoneLayout: {
      commandFn: actions.setCornerstoneLayout,
      storeContexts: [],
      options: {},
      context: 'VIEWER',
    },
    setWindowLevel: {
      commandFn: actions.setWindowLevel,
      storeContexts: ['viewports'],
      options: {},
    },
  };

  return {
    actions,
    definitions,
    defaultContext: 'ACTIVE_VIEWPORT::CORNERSTONE',
  };
};

export default commandsModule;
